import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

export const HomePageContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 10vw);
    background: url('/images/background.jpg');
    padding: 5vw 0;
    overflow: hidden;
    background-size: cover;
`

export const HomePageList = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    transition: .2s;

    /* & > div {
        flex-shrink: 0;
        width: calc(100vw - 10vw);
    } */
`
export const FormActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    /* background: rgba(255,255,255,0.1); */
    margin: 0 5vw;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 900px) {
        gap: 15px;
    }
`
export const ButtonsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    position: relative;

    @media screen and (max-width: 900px) {
        gap: 15px;
    }
`
export const WelcomeBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    line-height: 1;
`
export const LogoContainer = styled.div`
    svg {
        width: 100%;
    }
`
export const WelcomeText = styled.div`
    font-size: 5vh;
    color: white;
    font-weight: 700;
    text-align: center;
`
export const Button = styled.button`
    width: 100%;
    font-size: 2vh;
    height: 8vh;
    background: white;
    font-family: "Inter";
    border: 0;
    border-radius: 32px;
    transition: .05s;

    &:active {
        transform: scale(0.97);
    }

    &:disabled {
        background: rgba(255, 255, 255, 30%);
    }

    @media screen and (max-width: 900px) {
        border-radius: 12px;
        height: 6.5vh;
    }
`

// Counter
export const CounterList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3vh;
    width: 100%;
`
export const CounterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
`

export const CounterTitle = styled.div`
    font-family: "Inter";
    /* font-size: 44px; */
    /* font-size: 100%; */
    font-size: 1.2em;
    color: rgba(255, 255, 255, 40%);

    @media screen and (min-width: 900px) {
        font-size: 44px;
    }
`
export const CounterElements = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1vw;
    justify-content: center;
    width: 100%;
`
export const CounterElement = styled.div`
    background: rgba(255, 255, 255, 30%);
    width: calc(( 100vw - 15vw) / 6);
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
`
export const CountContent = styled.div`
    font-size: 4vh;
    color: white;
    font-weight: 700;
`

// PhoneBlock

export const LinkBack = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8vh;
    font-size: 2vh;
    color: white;
    font-family: "Inter";
    text-align: center;
`

export const ManagerState = styled(LinkBack)`
    color: rgba(255, 255, 255, 0.3);
    height: 5px;
    position: absolute;
    top: -30px;
`

// Keyboard
export const KeyboardBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
`

export const KeyboardValue = styled.div`
    font-size: 4vh;
    color: white;
    font-family: "Inter";
    font-weight: 700;
    text-align: center;
    height: 93px;
    display: flex;
    flex-direction: row;
    position: relative;

    & > div {
        /* min-width: 30px; */
        width: 50px;
    }

    @media screen and (max-width: 900px) {
        font-size: 30px;
        height: 40px;

        & > div {
            width: 22px;
        }
    }
`

export const SVGremoveBlock = styled.div`
    @media screen and (max-width: 900px) {
        svg {
            width: 32px;
            height: 32px;
        }
    }
`

export const KeyboardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 15px;
`
export const KeyboardButton = styled(Button)`
    position: relative;
    background: transparent;
    /* border: 1px white solid; */
    border: 1px rgba(255,255,255,0.5) solid;
    color: white;
    font-size: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${({ type }) => {
        if (type) {
            if (type === 'yellow') {
                return `border: 1px rgb(255 248 142 / 90%) solid;`;
            }
            if (type === 'green') {
                return `border: 1px rgb(76 175 80 / 90%) solid;`;
            }
        }
    }}
`

const keyboardLimiterAnimation = keyframes`
  0% {
    background: transparent;
  }

  50% {
    background: white;
  }

  to {
    background: transparent;
  }
`

export const KeyboardLimiter = styled.div`
    height: 100%;
    width: 0.3vw !important;
    background: white;
    min-width: unset !important;
    position: absolute;
    /* transition: .1s; */
    animation: ${keyboardLimiterAnimation} 1s infinite;
`



export const DoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;

    @media screen and (max-width: 900px) {
        svg {
            width: 120px;
            height: 120px;
        }
    }
`

export const DoneText = styled.div`
    font-size: 5vh;
    color: white;
    font-weight: 700;
    text-align: center;
`
export const DoneDescription = styled.div`
    font-size: 2.5vh;
    color: white;
    font-weight: 500;
    text-align: center;
`

export const RenderCounterList = styled.div`
    display: flex;
    flex-direction: column;
    transition: 1.5s;

    & > div {
        flex-shrink: 0;
        height: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`

export const HideContainer = styled.div`
    /* display: none; */
    opacity: 0;
    width: 1px;
    height: 1px;
`

export const ReloadButton = styled.div`
    z-index: 9;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
`

export const FullscreenButton = styled.div`
    z-index: 9;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
`

export const ExitFullscreenButton = styled.div`
    z-index: 9;
    position: absolute;
    left: 40px;
    top: 0;
    width: 40px;
    height: 40px;
`

export const StepContainer = styled.div`
    /* max-width: 1080px;
    margin: 0 auto; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: calc(100vw);
    flex-shrink: 0;

    & > div {
        max-width: 1080px;
        width: 100%;
    }
`

export const KeyboardIcon = styled.div`
    svg {
        width: 4vh;
        height: 4vh;
    }
`

// Managers List
export const ManagersListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    flex-shrink: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 930px;

    &::-webkit-scrollbar {
        width: 15px;               /* ширина scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.5);        /* цвет дорожки */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #13329c;    /* цвет плашки */
        border-radius: 20px;       /* закругления плашки */
        //border: 3px solid orange;  /* padding вокруг плашки */
    }
`
export const ManagerElement = styled.div`
    width: calc(100% - 8vh);
    display: flex;
    flex-direction: row;
    gap: 3vh;
    align-items: center;
    height: 150px;
    border: 1px rgba(255,255,255,0.5) solid;
    padding: 0 3vh;
    border-radius: 20px;
    flex-shrink: 0;
    
    &:active {
        transform: scale(0.97);
    }
`
export const ManagerOnlineStatus = styled.div`
    width: 50px;
    height: 50px;
    background: ${({ isOnline }) => isOnline ? '#4caf50' : '#9e9e9e'};
    border-radius: 50%;
`

export const ManagerName = styled.div`
    font-size: 2.5vh;
    color: white;
    font-family: "Inter";
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-direction: row;
    position: relative;
`

export const CodeIcon = styled.div`
    position: absolute;
    font-size: 23px;
    left: 12px;
    top: 12px;
    background: rgb(255 255 255 / 30%);
    padding: 5px 10px;
    border-radius: 10px;
`