import { createEvent, createStore } from "effector";

export const $currentElementView = createStore(0);
export const setCurrentViewFx = createEvent();
export const nextViewFx = createEvent();
export const prevViewFx = createEvent();
$currentElementView.on(setCurrentViewFx, (_, data) => data);
$currentElementView.on(nextViewFx, (_, data) => _ + 1);
$currentElementView.on(prevViewFx, (_, data) => _ - 1);

// Type Query
export const $typeQuery = createStore("");
export const setTypeQuery = createEvent();
$typeQuery.on(setTypeQuery, (_, typeQuery) => typeQuery);

// Keyboard
export const $keyboardVisual = createStore("");
export const $keyboardValue = createStore("");
export const $keyboardMask = createStore("");
export const setKeyboardVisualFx = createEvent();
export const setKeyboardValueFx = createEvent();
export const setKeyboardMaskFx = createEvent();
$keyboardVisual.on(setKeyboardVisualFx, (_, data) => data)
$keyboardValue.on(setKeyboardValueFx, (_, data) => data)
$keyboardMask.on(setKeyboardMaskFx, (_, data) => data)


// Phone Number
function formatPhoneNumber(number) {
    if (number.startsWith('8')) {
        return '+7' + number.slice(1);
    } else if (number.startsWith('+7')) {
        return number;
    } else if (number.startsWith('375')) {
        return '+375' + number.slice(3);
    } else if (number.startsWith('+375')) {
        return number;
    } else {
        return 'Некорректный формат номера';
    }
}

export const $phone = createStore("");
export const $formattedPhoneNumber = createStore("");
export const $phoneValue = createStore("");
export const $maskValue = createStore("");

export const setPhoneFx = createEvent();
export const setPhoneValueFx = createEvent();
export const setMaskValueFx = createEvent();

$phone.on(setPhoneFx, (_, data) => data)
$phoneValue.on(setPhoneValueFx, (_, data) => data)
$formattedPhoneNumber.on(setPhoneValueFx, (_, data) => formatPhoneNumber(data))
$maskValue.on(setMaskValueFx, (_, data) => data)

// Manager
export const $managerCode = createStore("");
export const setManagerCodeFx = createEvent();
$managerCode.on(setManagerCodeFx, (_, managerCode) => managerCode);

$phoneValue.watch((data) => console.log('PHONE => ', data));
$managerCode.watch((data) => console.log('MANAGER CODE => ', data));

// State wrote on keyboard
export const $stateWrote = createStore("phone");
export const setStateWroteFx = createEvent();
$stateWrote.on(setStateWroteFx, (_, stateWrote) => stateWrote);

// Image
export const $personalImage = createStore("");
export const setPersonalImageFx = createEvent();
$personalImage.on(setPersonalImageFx, (_, personalImage) => personalImage);